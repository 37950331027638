// @flow
import styled from 'styled-components';
import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { BrandedCallout } from '@latitude/callout';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  MARGIN
} from '@latitude/core/utils/constants';
import SvgInline from '@latitude/svg-inline/';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import Promo, { PromoSection } from '../components/Promo/Promo';
import OfferTiles from '@/components/OfferTiles/OfferTiles';
import Layout from '../components/layout';
import { PageContext } from '@/context/PageContext';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';
import PromoBanner from '@/components/lab-components/PromoBanner';
import HeroBanner from '@/components/lab-components/HeroBanner';
import './credit-cards/gem-visa-card.scss';

const Subheading = styled(Text)`
  max-width: 800px;
  margin: 0 auto ${MARGIN.M32};
  text-align: center;
`;

const createMailtoLink = () => {
  const email =
    'brad.graham@latitudefinancial.com;sash.winspear@latitudefinancial.com;';
  const subject = 'We’d like to understand more about the program';
  const body = `Thank you for contacting us, please provide your details and we’ll reply ASAP.`;
  return `mailto:${email}?subject=${subject}&body=${body}`;
};
const GemVisaOfferPage = () => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout>
      <main className="navigation-spacer car-loan-page">
        <Helmet>
          <link
            rel="canonical"
            href="https://www.gemfinance.co.nz/gem-visa-offers"
          />
          <title>Gem Visa Offers | Apply now | Gem by Latitude</title>
          <meta
            name="description"
            content="Gem Visa lets you shop for whatever you want, anywhere Visa is accepted, worldwide."
          />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            pageImage={require('../images/hero-gemvisa-offers.webp')}
            title={
              <>
                Gotta have it?
                <br />
                Let's make it happen.
              </>
            }
            subTitle="Enjoy 6 months interest free on everyday purchases of $250 or more*."
          >
            <div>
              <Link
                button={BUTTON_STYLE.PRIMARY}
                trackId="hero-apply-now"
                href="https://cards.gemvisa.co.nz/"
                trackEventData={{
                  category: 'cta',
                  action: 'application-link'
                }}
                css={{ width: 'fit-content' }}
              >
                Apply Now
              </Link>
              <br />
              <small>
                <sup>*</sup> Gem Visa T&Cs and fees apply including a $55
                establishment fee and $55 annual fee (charged $27.50
                half-yearly). Prevailing interest rate (currently 29.49% p.a.)
                applies to any remaining balance on the expiry of the interest
                free term. For cash advances, an interest rate of 29.95% p.a.
                and a $2 fee applies. Further information on rates and fees can
                be found at gemfinance.co.nz. Available to approved Gem Visa
                credit card customers only. Credit provided by Latitude
                Financial Services Ltd
              </small>
            </div>
          </PageHeaderSection>
        )}
        <OfferTiles />
        {state?.promoBannerData?.[0] ? (
          <PromoBanner {...state?.promoBannerData[0]} />
        ) : (
          <PromoSection>
            <Promo
              title={
                <>
                  Let&apos;s shop with <br />
                  Gem Visa.
                </>
              }
              description="Enjoy 6 months interest free on all purchases of $250 or more<sup>*</sup>, anywhere Visa is accepted."
              footerText="<br />*&nbsp;Gem Visa T&amp;Cs and fees apply including a $55 establishment fee and $55 annual fee (charged $27.50 half-yearly). Prevailing interest rate (currently 29.49% p.a.) applies to any remaining balance on the expiry of the interest free term. For cash advances, an interest rate of 29.95% p.a. and a $2 fee applies. Available to approved Gem Visa credit card customers only. Credit provided by Latitude Financial Services Ltd."
              frameImage={<SvgInline name="cardless-payment" />}
              frameAlignment="left"
              frameBackgroundColor="white"
              frameBorderColor="#b1dbff"
              ctaButtons={
                <Link
                  href="/credit-cards/gem-visa-card/"
                  button={BUTTON_STYLE.SECONDARY}
                  trackId="gemvisa-banner__find-out-more"
                  trackEventData={{
                    category: 'button-link',
                    action: 'internal-link',
                    location: "Let's shop with Gem Visa."
                  }}
                  trackProductId={['CCNZGEM']}
                  css={`
                    && {
                      @media (max-width: ${BREAKPOINT.LG}) {
                        margin-left: auto;
                        margin-right: auto;
                      }
                    }
                    background: #0046aa !important;
                    border-color: #0046aa !important;
                    max-width: 156px;
                  `}
                >
                  Find out more
                </Link>
              }
            />
          </PromoSection>
        )}
        <BrandedCallout
          hasConfettiBackground
          moreVerticalPadding
          heading="We’re taking on new retailers now"
          line1="Growth. No integration. New Customers. Zero merchant fees. Ready now. "
          cta={
            <Link
              trackId="talk-to-our-team"
              trackEventData={{
                location: 'Talk to our team!'
              }}
              href={createMailtoLink()}
              variant="secondary"
              css="margin-top: 16px;"
              button={BUTTON_STYLE.SECONDARY}
            >
              Talk to our team!
            </Link>
          }
        />
      </main>
    </Layout>
  );
};

export default GemVisaOfferPage;
